export default {
  validateSupplier: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择供应商'));
    } else {
      callback();
    }
  },
  validateDepartment: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择仓库'));
    } else {
      callback();
    }
  },
  validateDate: (rule, value, callback) => {
    if (!value) {
      callback(new Error('请选择日期'));
    } else {
      callback();
    }
  },
  validateProduct: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择胚布'));
    } else {
      callback();
    }
  },
  validateUnit: (rule, value, callback) => {
    if (!value.id) {
      callback(new Error('请选择单位'));
    } else {
      callback();
    }
  }
}
