<template>
  <div class="incoming-order main-panel large" v-loading="isPageLoading">
    <h3 class="panel-title" v-if="!layerInfo?.incoming.id">来胚单</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :class="{'update-btn': layerInfo?.incoming.id}"
               :loading="isLoading"
               @click="save">保存
    </el-button>
    <div class="top">
      <el-form :model="form" :rules="formRules" ref="mainForm" inline class="main-info" @submit.prevent>
        <el-form-item label="供应商" prop="supplier">
          <el-select :size="controlSize" filterable v-model="form.supplier" placeholder="请选择供应商" value-key="id">
            <el-option v-for="item in suppliers" :key="item.id" :label="item.name" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="仓库" prop="department">
          <el-select :size="controlSize" filterable v-model="form.department" placeholder="请选择仓库" value-key="id">
            <el-option v-for="item in departments" :key="item.id" :label="item.wareName" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="date">
          <el-date-picker v-model="form.date" type="date" :size="controlSize" placeholder="下单日期" :clearable="false" />
        </el-form-item>
      </el-form>
      <el-form :model="addInfo" :rules="addInfoRules" ref="addInfoForm" inline @submit.prevent>
        <el-form-item label="胚布名" prop="product">
          <div style="width: 150px">
            <el-select :size="controlSize" filterable v-model="addInfo.product" placeholder="请选择胚布" value-key="id">
              <el-option v-for="item in products" :key="item.id" :label="item.fabricName" :value="item" />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="批次" prop="batch">
          <div style="width: 100px">
            <el-input v-model="addInfo.batch" :size="controlSize" placeholder="批次" />
          </div>
        </el-form-item>
        <el-form-item label="数量" prop="number">
          <div style="width: 90px">
            <el-input type="number" v-model.number="addInfo.number" :size="controlSize" placeholder="数量" />
          </div>
        </el-form-item>
        <el-form-item label="条数" prop="stripsNumber">
          <div style="width: 90px">
            <el-input type="number" v-model.number="addInfo.stripsNumber" :size="controlSize" placeholder="条数" />
          </div>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <div style="width: 130px">
            <el-select :size="controlSize" filterable v-model="addInfo.unit" placeholder="请选择单位" value-key="id">
              <el-option v-for="item in units" :key="item.id" :label="item.name" :value="item" />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" :size="controlSize" @click="addRow">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" height="500">
      <el-table-column align="center" label="胚布名" prop="product.fabricName"></el-table-column>
      <el-table-column align="center" label="批次" prop="batch"></el-table-column>
      <el-table-column align="center" label="数量" prop="number"></el-table-column>
      <el-table-column align="center" label="条数" prop="stripsNumber"></el-table-column>
      <el-table-column align="center" label="单位" prop="unit.name"></el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <div class="options">
            <i class="update-icon el-icon-edit" title="修改" @click="openLayer({...scope.row, rowIndex: scope.$index})"></i>
            <el-popconfirm title="确认删除吗?" confirmButtonText='确认' cancelButtonText='取消' @confirm="del(scope.row)">
              <template #reference>
                <i class="del-icon el-icon-close" title="删除"></i>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <my-layer v-if="Object.keys(updateInfo).length"
              :title="updateInfo.layerTitle"
              :component="layerComponent"
              @handle="updateItem"
              :layer-info="updateInfo"
              @close="updateInfo = {}" />
  </div>
</template>

<script>
import _ from "lodash"
import validatorRules from "./validatorRules";
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";

export default {
  components: {MyLayer},
  props: {
    layerInfo: Object
  },

  data() {
    return {
      controlSize: "mini",
      form: {
        supplier: {},
        department: {},
        date: null
      },
      formRules: {
        supplier: [{required: true, validator: validatorRules.validateSupplier, trigger: "change"}],
        department: [{required: true, validator: validatorRules.validateDepartment, trigger: "change"}],
        date: [{required: true, validator: validatorRules.validateDate, trigger: "change"}]
      },
      addInfo: {
        product: {},
        batch: "",
        number: null,
        stripsNumber: null,
        unit: {},
        delflag: 1
      },
      addInfoRules: {
        product: [{required: true, validator: validatorRules.validateProduct, trigger: "change"}],
        batch: [{required: true, message: "请填写批次", trigger: "blur"}],
        number: [{required: true, message: "请填写数量", trigger: "blur"}],
        stripsNumber: [{required: true, message: "请填写条数", trigger: "blur"}],
        unit: [{required: true, validator: validatorRules.validateUnit, trigger: "change"}]
      },

      list: [],
      delList: [],

      isLoading: false,
      isPageLoading: false,

      layerComponent: undefined,
      updateInfo: {},

      suppliers: [],
      departments: [],
      products: [],
      units: []
    }
  },

  async mounted() {
    this.isPageLoading = true
    this.suppliers = await this.$getList.getSuppliers()
    this.departments = await this.$getList.getDepartments()
    this.products = await this.$getList.getProducts()
    this.units = await this.$getList.getUnits()
    if (this.layerInfo?.incoming.id) {
      this.getDetail()
    } else {
      this.form.date = new Date()
    }
    this.isPageLoading = false
  },

  methods: {
    getDetail() {
      const cloneInfo = _.cloneDeep(this.layerInfo)
      const detail = cloneInfo.incoming
      const list = cloneInfo.incomingInfos
      this.form.supplier = this.suppliers.find(i => i.id === detail.supplierId)
      this.form.department = this.departments.find(i => i.id === detail.departmentId)
      this.form.date = new Date(detail.date)
      this.list.push(...list.map(item => ({
        ...item,
        product: this.products.find(i => i.id === item.productId),
        batch: item.batch,
        number: item.num,
        stripsNumber: item.stripsNum,
        unit: this.units.find(i => i.id === item.companyId),
        delflag: item.delflag || 1
      })))
    },

    addRow() {
      this.$refs["addInfoForm"].validate(valid => {
        if (valid) {
          this.list.push(_.cloneDeep(this.addInfo))
          this.$refs["addInfoForm"].resetFields()
        }
      })
    },

    async openLayer(info) {
      this.layerComponent = shallowRef((await import("./update/update")).default)
      this.updateInfo = {...info, layerTitle: "产品修改"}
    },

    updateItem(info) {
      this.list.splice(info.rowIndex, 1, info)
      this.updateInfo = {}
    },

    del(info, index) {
      if (info.id) {
        this.delList.push({...info, delflag: 0})
      }
      this.list.splice(index, 1)
    },

    async save() {
      if (!this.list.length) {this.$message.warning("请添加数据"); return false}
      this.$refs["mainForm"].validate(async valid => {
        if (valid) {
          this.isLoading = true
          const sendData = {
            incoming: {
              supplierId: this.form.supplier.id,
              departmentId: this.form.department.id,
              date: this.$day(this.form.date).format("YYYY-MM-DD")
            },
            incomingInfos: this.list.concat(this.delList).map(item => ({
              id: item.id || null,
              productId: item.product.id,
              batch: item.batch,
              num: item.number,
              stripsNum: item.stripsNumber,
              companyId: item.unit.id,
              delflag: item.delflag
            }))
          }

          if (this.layerInfo?.incoming.id) {
            sendData.incoming.id = this.layerInfo.incoming.id
            const res = await this.$api.Stock.IncomingOrder.update(sendData).catch(() => {})
            this.isLoading = false

            if (res?.code === 200) {
              this.$message.success("修改成功")
              this.$emit("handle")
            } else {
              this.$message.error(res?.msg || "修改失败")
            }
          } else {
            const res = await this.$api.Stock.IncomingOrder.add(sendData).catch(() => {})
            this.isLoading = false

            if (res?.code === 200) {
              this.$message.success("保存成功")
              this.$refs["mainForm"].resetFields()
              this.$refs["addInfoForm"].resetFields()
              this.list = []
              this.form.date = new Date()
            } else {
              this.$message.error(res?.msg || "保存失败")
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "incoming-order";
</style>
